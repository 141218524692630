<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1">
          Ongphra Wallpaper Creator
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" style="max-height: 80vh;" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            ยินดีต้อนรับ 👋
          </b-card-title>
          <b-card-text class="mb-2">
            โปรดเข้าสู่ระบบเพื่อใช้งาน Ongphra Wallpaper Creator
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="อีเมล" label-for="login-email">
                <validation-provider #default="{ errors }" name="อีเมล" vid="email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="wallpaperStore@ongphra.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>ลืมรหัสผ่าน?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="รหัสผ่าน" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="••••••••"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <!-- <b-overlay :show="true" spinner-small>
                <b-button type="submit" variant="primary" block :disabled="invalid">
                  Sign in
                </b-button>
              </b-overlay> -->
              <button-widget
                type="submit"
                text="เข้าสู่ระบบ"
                block
                variant="primary"
                :loading="loading"
                :disabled="invalid"
              />
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>ยังไม่มีบัญชี ? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;สมัครสมาชิก</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/ongphra/login_light.svg'),

      // validation rules
      required,
      email,
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/ongphra/login_dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('AuthStore', ['updateUserInfoAuthStore']),
    async login() {
      this.$refs.loginForm.validate()
      this.loading = true
      const resp = await this.api.post('/api/login', {
        usernameOrEmail: this.userEmail,
        password: this.password,
      })

      if (!resp || typeof resp === 'string') {
        this.gDisplayToast('โปรดลองอีกครั้ง', 'อีเมลหรือรหัสผ่านไม่ถูกต้อง', 'danger')
        this.loading = false
        return
      }

      let ability = [
        { action: 'manage', subject: 'Auth' },
        { action: 'manage', subject: 'dashboard' },
        { action: 'manage', subject: 'partner-setting' },
        { action: 'all', subject: 'dashboard' },
      ]
      let role = 'partner'

      if (resp.userInfo.role_data_preview === 'partner' && resp.userInfo.approve_status_partner === true) {
        ability.push({ action: 'manage', subject: 'partner' })
        ability.push({ action: 'all', subject: 'wallpaper' })

        if (resp.userInfo?.amount_upload_per_day === 0 || resp.userInfo?.amount_upload_per_day >= 5) {
          ability.push({ action: 'all', subject: 'redeem-management' })
        }
      }

      switch (resp.userInfo.role_data_id) {
        case '7fa970cc-aa32-49bc-9cf6-50f996bc6385':
          ability = [
            { action: 'manage', subject: 'Auth' },
            { action: 'all', subject: 'admin' },
            { action: 'all', subject: 'wallpaper' },
            { action: 'all', subject: 'dashboard' },
            { action: 'all', subject: 'redeem-management' },
          ]
          role = 'admin'
          break
        case '6e5edbb0-f750-4c81-9669-81147585789f':
          ability = [
            { action: 'manage', subject: 'Auth' },
            { action: 'manage', subject: 'support' },
          ]
          role = 'support'
          break
        default:
          break
      }
      useJwt.setToken(resp.token)
      const userData = {
        id: 1,
        fullName: resp.name,
        username: resp.name,
        avatar: '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
        email: resp?.userInfo?.email,
        role,
        ability,
        extras: { eCommerceCartItemsCount: 5 },
        approve_status_partner: resp?.userInfo?.approve_status_partner ?? false,
      }

      // useJwt.setRefreshToken(response.data.refreshToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$ability.update(ability)

      /**
       * ?Query user info at state
       */
      this.updateUserInfoAuthStore(resp.userInfo)
      this.loading = false

      // [{action:"manage",subject:"all"}]
      this.$router.replace(getHomeRouteForLoggedInUser(role, resp.userInfo)).then(() => {
        // this.$toast({
        //   component: ToastificationContent,
        //   position: 'top-right',
        //   props: {
        //     title: `${resp.userInfo.name}`,
        //     icon: 'CoffeeIcon',
        //     variant: 'success',
        //     text: `คุณเข้าสู่ระบบสำเร็จแล้วในฐานะ ${role}`,
        //   },
        // })
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
